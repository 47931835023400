"use client";

import { cx } from "class-variance-authority";
import { usePathname, useSearchParams } from "next/navigation";
import { type Session } from "next-auth";
import { type MouseEvent, useState } from "react";
import { ChevronDown, LogOut, LogIn, Settings } from "react-feather";
import { Dropdown, DropdownItem, Button, Avatar } from "shared-ui";

import { signIn, signOut } from "@/authentication/authService";
import { isPreProduction, isProduction } from "@/constant";
import { Link } from "@/navigation";
import "./index.css";

type TUserDropdown = {
  session?: Session | null;
};

const UserDropdown = ({ session }: TUserDropdown) => {
  const pathname = usePathname();
  const callbackUrl = useSearchParams()?.get("callbackUrl") || "";
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const user = session?.user;

  const hideSetting =
    pathname?.startsWith("/verification") && (isPreProduction || isProduction);

  const userType = () => {
    if (pathname?.includes("/verification/step/")) {
      return "";
    }
    return user?.isSeller ? "Penyedia" : "Non Penyedia";
  };

  const toggleDropdown = () => setShowDropdown((prev) => !prev);

  const handleLogin = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    signIn("auth0", {
      callbackUrl,
    });
  };

  const handleLogout = () => {
    signOut();
  };

  if (!user?.id) {
    return (
      <Button
        id="login-btn"
        IconLeft={LogIn}
        data-test="header-profile-link ml-7"
        onClick={handleLogin}
      >
        Login
      </Button>
    );
  }

  return (
    <Dropdown
      data-test="header-dropdown"
      positionOffset={-4}
      trigger={
        <div
          onClick={toggleDropdown}
          className="mt-1 flex h-12 w-22 cursor-pointer items-center gap-2 border-b-0 border-l border-r-0 border-t-0 border-grey25 bg-primary25 pl-4 sm:w-24"
          data-test="header-userInfoSection"
        >
          <div className="w-10">
            <Avatar online={false} size="md" src={{ text: user.name }} />
          </div>
          {!!user?.username && (
            <>
              <div className="ml-2 flex w-19 flex-col">
                <span
                  data-test="header-user-fullname"
                  className="text-caption-sm-semibold truncate sm:text-base"
                >
                  {user?.username}
                </span>
                <div className="text-xs text-tertiary300">{userType()}</div>
              </div>
            </>
          )}
          <div data-test="header-dropdown-button" className="w-5">
            <ChevronDown
              size={18}
              className={cx(showDropdown && "rotate-180 transition-all")}
            />
          </div>
        </div>
      }
    >
      {!hideSetting && (
        <Link
          href="/settings/account-profile"
          data-test="header-profile-link"
          className=""
        >
          <DropdownItem
            data-test="setting"
            classes={{
              content: "hover:!bg-primary25 flex items-center cursor-pointer",
            }}
          >
            <Settings size={16} className="mr-2" /> Setting
          </DropdownItem>
        </Link>
      )}

      <DropdownItem
        data-test="logout-btn"
        onClick={handleLogout}
        classes={{
          content: "hover:!bg-primary25 flex items-center cursor-pointer",
        }}
      >
        <LogOut size={16} className="mr-2" /> Logout
      </DropdownItem>
    </Dropdown>
  );
};

export default UserDropdown;
