export function isSubpath(basePath: string, path: string | null): boolean {
  const basePathSegments = splitSegments(basePath)
  const pathSegments = splitSegments(path || '')

  if ((basePath === '/' && path !== '/') || basePath.includes('#')) return false

  if (basePathSegments.length > pathSegments.length) {
    return false
  }

  // Notes: this is just temporary, a func for check path order multishipment "/order-multishipment" all apps
  if (isOrderMultishipmentPath(pathSegments, basePathSegments)) {
    return true
  }

  for (let i = 0; i < basePathSegments.length; i++) {
    if (pathSegments[i] !== basePathSegments[i]) {
      return false
    }
  }

  return true
}

function splitSegments(path: string) {
  return new URL(path, 'dummy:/').pathname.replace(/\/$/, '').split('/')
}

function isOrderMultishipmentPath(pathSegments: string[], basePathSegments: string[]) {
  if (pathSegments[1] !== 'order-multishipment' && pathSegments[2] !== 'order-multishipment') {
    return false
  }

  if (basePathSegments.includes('orders', 1) || basePathSegments[1] === 'order') {
    return true
  }

  return false
}
