import { FLAG_DIET_COOKIE } from "./flag"

const APP_NAME = (process.env.NEXT_PUBLIC_APP_NAME || "account") as
  | "account"
  | "blacklist"
  | "buyer"
  | "internal"
  | "seller"
  | "verificator"

const GRAPHQL_LOCAL_URL = {
  account: "https://account.eproc.dev/",
  blacklist: "https://blacklist.eproc.dev/",
  buyer: "https://buyer.eproc.dev/",
  internal: "https://admin.eproc.dev/",
  seller: "https://seller.eproc.dev/",
  verificator: "https://verificator.eproc.dev/",
}

const BASE_URL = {
  account: process.env.NEXT_PUBLIC_ACCOUNT_URL,
  blacklist: process.env.NEXT_PUBLIC_BLACKLIST_URL,
  buyer: process.env.NEXT_PUBLIC_AUTH_BASE_URL_BUYER,
  internal: process.env.NEXT_PUBLIC_AUTH_BASE_URL_INTERNAL,
  seller: process.env.NEXT_PUBLIC_AUTH_BASE_URL_SELLER,
  verificator: process.env.NEXT_PUBLIC_VERIFICATOR_URL,
}

const IS_DEVELOPMENT =
  (process.env.NEXT_PUBLIC_ENV || "development") === "development"

export const GRAPHQL_URL = IS_DEVELOPMENT
  ? `${GRAPHQL_LOCAL_URL?.[APP_NAME]}graphql`
  : `${BASE_URL?.[APP_NAME]}graphql`

export const GRAPHQL_WS_URL = GRAPHQL_URL.replace("https", "wss")

/**
 * Note: Digunakan untuk mengarahkan websocket ke pages api 'api/getUploadStatus'
 */
export const GTP_TYK_WS_URL_V2 = (
  FLAG_DIET_COOKIE
    ? `${process.env.NEXTAUTH_URL}graphql` || ""
    : process.env.NEXT_PUBLIC_CMS_FEDERATED_TYK_URL ||
      process.env.NEXT_PUBLIC_FEDERATED_TYK_URL ||
      ""
).replace("https", "wss")

export const UPLOAD_PROXY_URL = IS_DEVELOPMENT
  ? GRAPHQL_LOCAL_URL?.[APP_NAME]
  : process.env.NEXT_PUBLIC_UPLOAD_PROXY_URL || "https://uploader.eproc.dev"
