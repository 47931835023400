"use client"
import { useState, useEffect } from "react"

type TViewportSize = {
  width: number
  height: number
}

type TUseViewportSize = () => void

const useViewportSize = (callback?: TUseViewportSize) => {
  const [viewportSize, setViewportSize] = useState<TViewportSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setViewportSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })

      callback && callback()
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return viewportSize
}

export default useViewportSize
