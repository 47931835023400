"use client"

import { TGetSignedUrl, TSocketMessage } from "../types"
import { useEffect, useRef, useState } from "react"
import { AxiosError, type CancelTokenSource } from "axios"
import { GRAPHQL_WS_URL, UPLOAD_PROXY_URL } from "../constants/api"
import { createMutation } from "react-query-kit"
import axiosProgress from "./axiosProgress"
import axios from "axios"
import encryptUploadPayload from "./encryptUploadPayload"
import jwt from "jsonwebtoken"
import removeLastCharIfMatch from "./removeLastCharIfMatch"

export const FINISH_STATUS = [
  "UPLOAD_VALIDATION_FAILED",
  "UPLOAD_VIRUS_DETECTED",
  "UPLOAD_SUCCESS",
]

const SCANNING_STATUS = {
  INITIATE_UPLOAD: 92,
  UPLOAD_ON_METADATA_VALIDATION: 95,
  UPLOAD_ON_MALWARE_SCANNING: 98,
}

type TTimeout = {
  /**
   * timeout duration in ms
   */
  duration: number
  message: string
}

type TUseUploadProps<SignedUrlParam> = {
  onError?: (err: string) => void
  onSuccessUpload?: (signedUrl: TGetSignedUrl, file?: File) => void
  getSignedUrl: (
    param: SignedUrlParam & TAdditionalUploadData
  ) => Promise<TGetSignedUrl | undefined>
  errorMessage?: {
    validationFailed?: string
    virusDetected?: string
  }
  timeout?: TTimeout
}

type TAdditionalUploadData = {
  file?: File
  clientIdentityKey?: string
}

const mappingEncryptUploadError = (errMsg: string) => {
  if (errMsg.includes("Network Error")) {
    return "Telah terjadi kesalahan, mohon ulangi dalam beberapa saat lagi"
  }
  if (errMsg.includes("413 Request Entity Too Large")) {
    return "Telah terjadi kesalahan dari pihak kami, mohon hubungi call center kami."
  }
  if (
    errMsg.includes(
      "uploading error because file size exceeds limit or content type is different"
    )
  ) {
    return "Ukuran file terlalu besar atau format file tidak sesuai."
  }
  return errMsg
}

const useUploadGcs = <SignedUrlParam>({
  onSuccessUpload,
  onError,
  getSignedUrl,
  errorMessage,
  timeout,
}: TUseUploadProps<SignedUrlParam>) => {
  const websocket = useRef<WebSocket>()
  const source = useRef<CancelTokenSource>()
  const [percentage, setPercentage] = useState(0)
  const [file, setFile] = useState<File>()
  const [estimatedTimeLeft, setEstimatedTimeLeft] = useState({
    seconds: 0,
    minutes: 0,
    hour: 0,
  })

  async function uploadFile(
    signedUrl: string,
    payload: File | FormData,
    method: "POST" | "PUT"
  ) {
    const startTime = Date.now()
    let lastProgressPercentage = 0
    let progressTimeout: NodeJS.Timeout
    source.current = axios.CancelToken.source()

    function resetTimeout() {
      if (progressTimeout) {
        clearTimeout(progressTimeout)
      }
      progressTimeout = setTimeout(() => {
        cancel(timeout?.message)
      }, timeout?.duration)
    }

    const fetchFunction = method === "PUT" ? axios.put : axios.post
    return await fetchFunction(signedUrl, payload, {
      onUploadProgress(progressEvent) {
        const {
          progressPercentage,
          estimatedHoursLeft,
          estimatedMinutesLeft,
          estimatedSecondsLeft,
        } = axiosProgress(progressEvent, startTime, 100)

        if (progressPercentage !== lastProgressPercentage) {
          lastProgressPercentage = progressPercentage
          setPercentage(Math.round(progressPercentage * 0.88))

          setEstimatedTimeLeft({
            seconds: estimatedSecondsLeft,
            minutes: estimatedMinutesLeft,
            hour: estimatedHoursLeft,
          })

          if (timeout?.duration) {
            resetTimeout() // Reset timeout only if timeout is enabled
          }
        }
      },
      cancelToken: source?.current?.token,
    })
  }

  const handleError = (err: string) => {
    if (onError) {
      onError(
        err || "Telah terjadi kesalahan, mohon ulangi dalam beberapa saat lagi"
      )
    }
    setPercentage(0)
  }

  const closeSocket = () => {
    if (
      websocket.current &&
      websocket.current.readyState === websocket.current.OPEN
    ) {
      websocket.current.send(JSON.stringify({ id: "1", type: "stop" }))
      websocket.current.close()
      websocket.current = undefined
    }
  }

  const cancel = (message?: string) => {
    closeSocket()
    if (source.current) {
      source.current.cancel(message ?? "Operation canceled by the user.")
      source.current = undefined
    }
    setPercentage(0)
  }

  const createSocket = (signedUrlParam: TGetSignedUrl, file?: File) => {
    websocket.current = new WebSocket(GRAPHQL_WS_URL, "graphql-ws")

    websocket.current.onopen = () => {
      const connectionInit = JSON.stringify({ type: "connection_init" })
      const getUploadStatusGql = JSON.stringify({
        id: "1",
        type: "start",
        payload: {
          query: `subscription SS {\n  getUploadStatus(input: {token: "${signedUrlParam.token}"})\n}\n`,
          operationName: "SS",
        },
      })
      if (websocket?.current?.readyState === WebSocket.OPEN) {
        websocket?.current?.send(connectionInit)
        websocket?.current?.send(getUploadStatusGql)
      } else {
        websocket?.current?.addEventListener("open", () => {
          websocket?.current?.send(connectionInit)
          websocket?.current?.send(getUploadStatusGql)
        })
      }
    }

    websocket.current.onmessage = (event: MessageEvent<string>) => {
      const data = JSON.parse(event.data) as TSocketMessage
      const status = data?.payload?.data?.getUploadStatus || ""

      if (!FINISH_STATUS.includes(status) || !websocket.current) {
        setPercentage(
          (prev) =>
            SCANNING_STATUS?.[status as keyof typeof SCANNING_STATUS] || prev
        )

        return
      }
      switch (status) {
        case FINISH_STATUS[2]:
          setPercentage(0)
          onSuccessUpload && onSuccessUpload(signedUrlParam, file)
          break
        case FINISH_STATUS[1]:
          setPercentage(0)
          handleError(errorMessage?.virusDetected ?? "")
          break
        case FINISH_STATUS[0]:
          setPercentage(0)
          handleError(errorMessage?.validationFailed ?? "")
          break
        default:
          handleError("")
          break
      }
      closeSocket()
    }

    websocket.current.onerror = () => {
      handleError("")
      setPercentage(0)
      closeSocket()
    }
  }

  const {
    mutate: upload,
    status,
    data: signedUrlInfo,
    mutateAsync: uploadAsync,
    reset,
  } = createMutation<
    TGetSignedUrl | undefined,
    SignedUrlParam & TAdditionalUploadData
  >({
    mutationFn: async (params) => {
      handleInitializeUploadState(params.file)
      return await getSignedUrl(params)
    },
  })({
    onSuccess: async (result, variable) => {
      try {
        if (result?.signedUrl) {
          await uploadFile(result.signedUrl, variable.file as File, "PUT")
          createSocket(result, variable.file)
          return
        }

        if (result?.clientIdentity && result.encryptedServerRecipient) {
          const encryptedFormData = await encryptUploadPayload(
            result.clientIdentity,
            result.encryptedServerRecipient,
            variable.file
          )
          const uploadToken = jwt.decode(result.token) as { token: string }

          await uploadFile(
            `${removeLastCharIfMatch(UPLOAD_PROXY_URL, "/")}/upload/${
              uploadToken?.token
            }`,
            encryptedFormData,
            "POST"
          )
          createSocket(result, variable.file)
          return
        }
      } catch (err) {
        console.log({ err })
        if (err instanceof AxiosError && err.code !== "ERR_CANCELED") {
          handleError(
            mappingEncryptUploadError(err.response?.data ?? err.message)
          )
        } else {
          handleError("")
        }
        return
      }
    },
    onError: (err) => {
      handleError(err.message)
    },
  })

  const handleInitializeUploadState = (paramFile?: File) => {
    setFile(paramFile)
    setPercentage(1)
  }

  useEffect(() => {
    window.addEventListener("beforeunload", closeSocket)
    return () => {
      window.addEventListener("beforeunload", closeSocket)
    }
  }, [])

  return {
    percentage,
    cancel,
    file,
    upload,
    status,
    signedUrlInfo,
    uploadAsync,
    reset,
    estimatedTimeLeft,
  }
}

export default useUploadGcs
