'use client'
import { cx } from 'class-variance-authority'
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { AlertTriangle, Circle } from 'react-feather'
import Button from '../Button/Button'
import { CDN_URL } from 'shared-config/clientConfig'
import { TUpload } from './Upload.type'
import { formatBytes, isValidFileName, validateImageDimensions } from 'shared-utils'
import ProgressBar from '../ProgressBar/ProgressBar'
import UploadLabel from './UploadLabel'

const fileNameValidator = (file: File) => {
  if (!isValidFileName(file.name)) {
    return {
      code: 'invalid-name',
      message: 'File name can only contain letters, numbers, signs "-"(minus) and "_"(underscore)!',
    }
  }
  return null
}

const Upload = forwardRef<HTMLInputElement, TUpload>(function Upload(
  {
    accept,
    disabled = false,
    maxSize,
    maxFiles,
    minSize,
    multiple = false,
    handleOnDropAccepted,
    handleOnDropRejected,
    error,
    uploadInfo,
    fileUrl,
    name,
    size,
    progress,
    progressStyle,
    handleRemoveFile,
    handleCancelFile,
    previewOnly,
    file: defaultFile,
    setFile: setFileStateParam,
    dimension,
    hideUploadedDocs,
    disableFileNameValidation,
    errorDivId,
    subTitle,
  },
  ref,
) {
  const inputEl = useRef<HTMLInputElement | null>(null)
  const [fileState, setFileState] = useState<File | undefined>(defaultFile)
  const [file, setFile] = setFileStateParam ? [defaultFile, setFileStateParam] : [fileState, setFileState]

  const allowedFileType = useMemo(() => {
    if (!accept) {
      return ''
    }

    const allExtensions = Object.values(accept).flatMap((extensions) => extensions)
    const uniqueExtensions = [...new Set(allExtensions)]
    const formattedExtensions = uniqueExtensions.join(', ')

    return formattedExtensions ? `Format ${formattedExtensions}` : ''
  }, [accept])

  const formattedMaxSize = maxSize ? formatBytes(maxSize) : ''
  const formattedMinSize = minSize ? formatBytes(minSize) : ''

  const onDropAccepted = useCallback(async (acceptedFile: File[]) => {
    const tempFile = acceptedFile?.[0]
    if (!tempFile) {
      return
    }
    if (dimension) {
      try {
        await validateImageDimensions({
          file: tempFile,
          ...dimension,
        })
      } catch (error) {
        handleOnDropRejected && handleOnDropRejected(error instanceof Error ? error.message : String(error))
        return
      }
    }
    handleOnDropAccepted && handleOnDropAccepted(acceptedFile)
    setFile(tempFile)
  }, [])

  const onDropRejected = useCallback((rejectedFiles: FileRejection[]) => {
    const errors = rejectedFiles?.[0]?.errors
    if (!errors?.length) {
      return
    }

    const errorMsg: string[] = errors.map((err) => {
      switch (err.code) {
        case 'file-invalid-type':
          return `Dokumen harus dalam format ${allowedFileType}.`
        case 'file-too-large':
          return `Ukuran dokumen maksimal ${formattedMaxSize}.`
        case 'file-too-small':
          return `Ukuran dokumen minimal ${formattedMinSize}.`
        case 'invalid-name':
          return 'Nama file hanya boleh mengandung huruf, angka, tanda " - " (minus) dan " _ " (garis bawah).'
        case 'too-many-files':
          return `Jumlah file yang unggah tidak boleh melebihi dari ${!multiple ? 1 : maxFiles ?? 1}`
        default:
          return ''
      }
    })

    handleOnDropRejected && handleOnDropRejected(errorMsg.join('||'))
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    minSize,
    multiple,
    onDropRejected,
    onDropAccepted,
    validator: disableFileNameValidation ? undefined : fileNameValidator,
    noClick: true,
    disabled: !!fileUrl || disabled || previewOnly,
  })

  const onClickDeleteBtn = () => {
    handleRemoveFile?.()
    setFile(undefined)
    if (inputEl.current) inputEl.current.value = ''
  }

  const onClickCancelBtn = () => {
    handleCancelFile?.()
    setFile(undefined)
    if (inputEl.current) inputEl.current.value = ''
  }

  const errorsData = !!error && error.split('||')
  const fileSize = size || file?.size
  const formattedSize = fileSize ? formatBytes(fileSize).toLowerCase() : undefined

  return (
    <div>
      <div
        {...(previewOnly ? {} : getRootProps())}
        className={cx(
          'flex w-full items-center rounded-5 border',
          fileUrl || previewOnly ? 'border-solid' : 'border-dashed',
          'p-4',
          error ? 'bg-error50' : fileUrl || previewOnly ? 'bg-primary25' : 'bg-grayscale10',
          isDragActive ? 'border-secondary400' : error ? 'border-error500' : 'border-grayscale30',
        )}
        ref={ref}
      >
        <div className="flex grow">
          <div className={cx('mr-4 h-10 w-10 shrink-0 rounded-full p-2', !error && 'bg-information50')}>
            <img
              src={`${CDN_URL}/icons/${previewOnly || file || fileUrl ? 'iconFile' : 'iconUpload'}.svg`}
              className={cx('h-6 w-6', error && 'hue-rotate-[140deg]')}
            />
          </div>
          <div className={cx('flex grow flex-col gap-1 pr-4', hideUploadedDocs && 'justify-center')}>
            {!!fileUrl || previewOnly ? (
              <UploadLabel
                title={name || file?.name || ''}
                subtitle={formattedSize ?? subTitle}
                fileURL={fileUrl}
                hideUploadedDocs={hideUploadedDocs}
              />
            ) : (
              <>
                <UploadLabel
                  title={file ? file.name : 'Pilih atau tarik dokumen di sini'}
                  subtitle={`${allowedFileType} (max. ${formattedMaxSize.toLowerCase()})`}
                />
                {!!progress && (
                  <ProgressBar
                    value={progress}
                    numberIndicatorStyle={progressStyle}
                    className={'mt-3 !h-2 !border-none'}
                  />
                )}
              </>
            )}
          </div>
        </div>
        {!previewOnly && (
          <>
            {' '}
            {!!fileUrl && (
              <Button
                type="button"
                id="delete-document-btn"
                onClick={onClickDeleteBtn}
                variant="transparentAccent"
                className="h-fit"
              >
                Hapus
              </Button>
            )}{' '}
            {!!progress && !fileUrl && (
              <Button
                type="button"
                id="cancel-document-btn"
                onClick={onClickCancelBtn}
                variant="transparentAccent"
                disabled={disabled}
                className="h-fit"
              >
                Batalkan
              </Button>
            )}{' '}
            {!fileUrl && !progress && (
              <Button
                className="mt-1 h-[30px] !min-h-[30px] w-fit shrink-0 px-2 py-[6px] !text-xs font-semibold"
                variant="outline2"
                type="button"
                id="upload-document-btn"
                disabled={disabled}
                onClick={() => inputEl.current?.click()}
              >
                Unggah Dokumen
              </Button>
            )}
          </>
        )}
        <input {...getInputProps()} ref={inputEl} />
      </div>
      {uploadInfo && (
        <div className="mt-2 flex gap-2">
          <p className="text-caption-sm-regular text-tertiary300">
            File hanya menerima karakter-karakter berikut, huruf, angka, minus ( - ) dan garis bawah ( _ )
          </p>
        </div>
      )}
      {errorsData && (
        <div id={errorDivId} className="mt-2 flex gap-2">
          <AlertTriangle className="place-self-center text-error500" size={12} />
          <div>
            {errorsData.map((err: string, index: number) => (
              <span
                className={cx('text-caption-sm-regular flex gap-[6px] text-error500', errorsData.length > 1 && 'mt-1')}
                key={index}
              >
                {errorsData.length > 1 && <Circle className="place-self-center text-error500" fill="red" size={3} />}
                {err}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  )
})

export default Upload
