import age from "age-encryption"

export default async function encryptUploadPayload(
  clientIdentity: string,
  encryptedServerRecipient: string,
  payloadToEncrypt?: File
) {
  const { Encrypter, Decrypter } = await age()
  const decrypter = new Decrypter()
  decrypter.addIdentity(clientIdentity)
  const decodedServerRecipient = Buffer.from(encryptedServerRecipient, "base64")
  const serverRecipient = decrypter.decrypt(decodedServerRecipient, "text")

  const buff = await payloadToEncrypt?.arrayBuffer()
  const arr = new Uint8Array(buff as ArrayBuffer)

  const encrypter = new Encrypter()
  encrypter.addRecipient(serverRecipient)
  const encryptedFile = encrypter.encrypt(arr)
  const n = new Blob([encryptedFile], { type: "application/octet-stream" })
  // TEST CODES
  // const encryptedTest = encrypter.encrypt("kucing mengeong")
  // const { Decrypter: ServerDecrypter } = await age()
  // const serverDecrypter = new ServerDecrypter()
  // serverDecrypter.addIdentity(
  //   "AGE-SECRET-KEY-10AFAQDXNZFRXARYY8KLQGY8FJ0KSQSUDK4NTAA80HHDM8598EAZSL58FXM"
  // )
  // const unencryptedTest = serverDecrypter.decrypt(encryptedTest, "text")
  // console.log({ unencryptedTest, encryptedTest })

  // debugger
  const formData = new FormData()
  formData.append("file", n)

  return formData
}
