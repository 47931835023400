export default function removeLastCharIfMatch(
  stringToModify: string,
  charToRemove: string
) {
  if (stringToModify.endsWith(charToRemove)) {
    return stringToModify.slice(0, -1)
  }

  return stringToModify
}
